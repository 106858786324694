import { useState, useEffect, useRef } from "react";
import isServer from "helpers/isServer";

export default ({ callback, root = null, rootMargin, threshold = 0 }) => {
  const [node, setNode] = useState(null);

  const observer = useRef(
    isServer
      ? null
      : new IntersectionObserver(callback, {
          root,
          rootMargin,
          threshold,
        })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) {
      currentObserver.observe(node);
    }

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode];
};
