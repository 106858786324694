import React, { useState } from "react";
import { H1, H4 } from "app/components/Typography";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import { ClassList } from "app/components/ClassList";
import useIntersect from "hooks/useIntersect";
import { useParams } from "react-router";

const RelatedClasses = () => {
  const [areClassesShowing, setClassesShowing] = useState(false);
  const { slug } = useParams();
  const [{ relatedClasses }] = useGetProgramsV2({
    variables: { slug },
  });
  const [sentinel] = useIntersect({
    callback: entries => {
      if (entries[0]?.isIntersecting) {
        setClassesShowing(true);
      }
    },
  });

  if (relatedClasses?.length < 0) {
    return null;
  }

  return (
    <>
      <H1
        mb="16px"
        ref={sentinel}
        className="watch-scroll"
        id="related-classes-container"
        scrollMargin="76px"
      >
        Related Classes
      </H1>
      <H4 mb="32px" color="monochrome.5">
        Enjoyed this program? Check out these similar classes!
      </H4>
      {areClassesShowing && relatedClasses && (
        <ClassList
          classes={relatedClasses}
          selectedFrom="Program Related Classes"
          sentinel={sentinel}
        />
      )}
    </>
  );
};

export default RelatedClasses;
